import * as React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { useRouter } from "next/router";
import { REMOTE_CONFIG_KEY, useFirebaseRemoteConfig } from "@amondz/firebase";

import { setCookie } from "@lib/utility/settingCookies";
import { RootStateType } from "@store/modules";
import { hellobarCookie, HellobarStateType } from "@store/modules/base";
import { DELETE_WHITE_BTN_IMAGE_PATH } from "@constants/static/images";
import { COOKIE_NAME } from "@constants/service/common/modal";
import { GIFT_DELIVERY_URL_PATH } from "@constants/url/internalUrlConstants";
import { AMONDZ_HELLO_BAR_DEEP_LINK_URL } from "@constants/url/externalUrlConstants";
import { DiscountBtn, HellobarExitBtnImg, HellobarTitle, HellobarWrap } from "./style";

const Hellobar: FC = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { getJson } = useFirebaseRemoteConfig();
  const { isHellobarCookieEnrolled } = useSelector<RootStateType, HellobarStateType>(
    (state: RootStateType) => state.base.HellobarState,
  );

  const handleClickExitBtn = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();
    setCookie(COOKIE_NAME.HELLO_BAR);
    dispatch(hellobarCookie({ isHellobarCookieEnrolled: true }));
  };

  const helloBarMessage =
    getJson(REMOTE_CONFIG_KEY.BENEFIT).helloBarMessage || "첫 구매 시 바로 사용 가능한 쿠폰팩을 드려요✨";

  // 쿠키가 등록이 되어있거나 데스크탑에서 로그인했을 경우, 선물하기 관련 페이지일 경우 헬로바 미노출
  if (isHellobarCookieEnrolled || router.pathname.includes(GIFT_DELIVERY_URL_PATH)) {
    return null;
  }

  return (
    <Link href={AMONDZ_HELLO_BAR_DEEP_LINK_URL} className="hello-bar">
      <HellobarWrap>
        <HellobarTitle>{helloBarMessage}</HellobarTitle>
        <DiscountBtn>쿠폰 받기</DiscountBtn>
        <HellobarExitBtnImg src={DELETE_WHITE_BTN_IMAGE_PATH} alt="delete_btn" onClick={handleClickExitBtn} />
      </HellobarWrap>
    </Link>
  );
};

export default Hellobar;
