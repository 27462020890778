import styled from "@styles/themedComponents";

export const BottomSideFooterStyle = styled.article`
  .company-info {
    padding: 15px 15px 90px;

    .amondz-logo {
      margin-bottom: 15px;
    }

    ul {
      li {
        &:not(.last-child) {
          margin-bottom: 3px;
        }

        &.company-address,
        &.executive {
          span + span {
            margin-left: 14px;
          }
        }

        line-height: 17px;
        font-size: 11px;
        letter-spacing: -0.3px;
        color: ${(props) => props.theme.color.amondz_black};

        span {
          opacity: 0.7;
        }

        a {
          margin-left: 10px;
          font-weight: 500;

          &.business-license-information {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
`;
