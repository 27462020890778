import * as React from "react";
import { useCallback, useState } from "react";
import produce from "immer";
import { AmondzJapanLogo, AmondzMagazineLogo, AmondzUSLogo } from "@amondz/components";
import { SafeRecord, ValueOf } from "@amondz/types";
import Icon from "@components/common/Icon/Icon";
import {
  FOOTER_SECTION_CLOSE_BTN_IMAGE_PATH,
  FOOTER_SECTION_OPEN_BTN_IMAGE_PATH,
} from "@constants/static/images";
import { FOOTER_SOCIAL_BTN_LIST, FOOTER_TERM_BTN_LIST } from "@constants/service/base/footer";
import {
  BEJEWEL_COMPANY_SITE_URL,
  BYAMONDZ_JAPAN_URL,
  BYAMONDZ_US_URL,
  ONE_SERVICE_URL,
  AMONDZ_MAGAZINE_URL,
} from "@constants/url/externalUrlConstants";
import { TopSideFooterStyle } from "./style";

const FOOTER_CONTENTS_TOGGLE: SafeRecord<string, boolean> = {
  // 약관 정보
  AMONDZ_TERMS: false,
  // 법적 고지
  LEGAL_NOTICE: false,
  // SNS 채널
  AMONDZ_CHANNEL: false,
  // 글로벌 채널
  AMONDZ_GLOBAL: false,
  // 매거진
  AMONDZ_MAGAZINE: false,
};

// 회사소개, 입점신청, 아몬즈 소셜 채널, 법적고지 등을 담고 있는 상단 mobile footer
const TopSideFooter = () => {
  const [footerSectionExpandedState, setFooterSectionExpandedState] = useState(FOOTER_CONTENTS_TOGGLE);

  const onToggleSection = useCallback(
    (key: keyof typeof FOOTER_CONTENTS_TOGGLE) => {
      setFooterSectionExpandedState(
        produce(footerSectionExpandedState, (draft) => {
          const currentValue: ValueOf<typeof FOOTER_CONTENTS_TOGGLE> = draft[key];
          if (draft[key] !== undefined) {
            draft[key] = !currentValue;
          }
        }),
      );
    },
    [footerSectionExpandedState],
  );

  return (
    <TopSideFooterStyle>
      <div className="section">
        <div className="section-title" onClick={() => onToggleSection('AMONDZ_TERMS')}>
          약관
          <Icon
            className="section-toggle-btn"
            src={
              footerSectionExpandedState.AMONDZ_TERMS
                ? FOOTER_SECTION_CLOSE_BTN_IMAGE_PATH
                : FOOTER_SECTION_OPEN_BTN_IMAGE_PATH
            }
          />
        </div>
        {footerSectionExpandedState.AMONDZ_TERMS && (
          <ul className="section-list term-btn-list">
            {FOOTER_TERM_BTN_LIST.map((item, index) => (
              <li key={index}>
                <a href={item.pageUrl} target="_blank" rel="noreferrer">
                  <p>{item.title}</p>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="section">
        <div className="section-title">
          <a href={BEJEWEL_COMPANY_SITE_URL} target="_blank" rel="noreferrer">
            회사소개
          </a>
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          <a href={ONE_SERVICE_URL} target="_blank" rel="noreferrer">
            입점신청
          </a>
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          <a href="https://forms.gle/Yn8X52yg9W2joNCUA" target="_blank" rel="noreferrer">
            제휴문의
          </a>
        </div>
      </div>
      <div className="section">
        <div className="section-title" onClick={() => onToggleSection('LEGAL_NOTICE')}>
          법적 고지
          <Icon
            className="section-toggle-btn"
            src={footerSectionExpandedState.LEGAL_NOTICE ? FOOTER_SECTION_CLOSE_BTN_IMAGE_PATH : FOOTER_SECTION_OPEN_BTN_IMAGE_PATH}
          />
        </div>
        {footerSectionExpandedState.LEGAL_NOTICE && (
          <ul className="section-list legal-notice">
            <li>
              <p>
                (주) 비주얼은 결제정보의 중개서비스 또는 통신판매중개시스템의 제공자로서, 통신판매의 당사자가 아니며,
                상품의 주문, 배송 및 환불 등과 관련한 의무와 책임이 각 판매자에게 1차로 있습니다. 단, (주) 비주얼 또한
                상품의 주문, 배송 및 환불등과 관련한 의무와 책임을 가지고있습니다.
              </p>
            </li>
          </ul>
        )}
      </div>
      <div className="section">
        <div className="section-title" onClick={() => onToggleSection("AMONDZ_CHANNEL")}>
          아몬즈를 다양한 채널에서 만나보세요.
          <Icon
            className="section-toggle-btn"
            src={footerSectionExpandedState.AMONDZ_CHANNEL ? FOOTER_SECTION_CLOSE_BTN_IMAGE_PATH : FOOTER_SECTION_OPEN_BTN_IMAGE_PATH}
          />
        </div>
        {footerSectionExpandedState.AMONDZ_CHANNEL && (
          <ul className="section-list social-btn-list">
            {FOOTER_SOCIAL_BTN_LIST.map((item, index) => (
              <li key={index}>
                <a href={item.pageUrl} target="_blank" rel="noreferrer">
                  <Icon src={item.imgPath} />
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="section">
        <div className="section-title" onClick={() => onToggleSection('AMONDZ_GLOBAL')}>
          글로벌
          <Icon
            className="section-toggle-btn"
            src={footerSectionExpandedState.AMONDZ_GLOBAL ? FOOTER_SECTION_CLOSE_BTN_IMAGE_PATH : FOOTER_SECTION_OPEN_BTN_IMAGE_PATH}
          />
        </div>
        {footerSectionExpandedState.AMONDZ_GLOBAL && (
          <ul className="section-list global-btn-list">
            <li>
              <a href={BYAMONDZ_JAPAN_URL} target="_blank" rel="noreferrer">
                <AmondzJapanLogo width={82} height={12} />
              </a>
            </li>
            <li>
              <a href={BYAMONDZ_US_URL} target="_blank" rel="noreferrer">
                <AmondzUSLogo width={70} height={12} />
              </a>
            </li>
          </ul>
        )}
      </div>
      <div className="section">
        <div className="section-title" onClick={() => onToggleSection('AMONDZ_MAGAZINE')}>
          매거진
          <Icon
            className="section-toggle-btn"
            src={footerSectionExpandedState.AMONDZ_MAGAZINE ? FOOTER_SECTION_CLOSE_BTN_IMAGE_PATH : FOOTER_SECTION_OPEN_BTN_IMAGE_PATH}
          />
        </div>
        {footerSectionExpandedState.AMONDZ_MAGAZINE && (
          <ul className="section-list amondz_magazine">
            <li>
              <a href={AMONDZ_MAGAZINE_URL} target="_blank" rel="noreferrer">
                <AmondzMagazineLogo width={92} height={12} />
              </a>
            </li>
          </ul>
        )}
      </div>
    </TopSideFooterStyle>
  );
};

export default TopSideFooter;
