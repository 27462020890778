import { useEffect, useState } from "react";
import { throttle } from "lodash";

import { SCROLL_DIRECTION } from "@constants/enum/baseEnums";

interface useScrollDirectionType {
  initialDirection?: SCROLL_DIRECTION;
  thresholdPixels?: number;
}

/**
 * 스크롤 방향
 *
 * @param {useScrollDirectionType} props 초기 방향과 스크롤 측정 시간 단위 값
 *
 * @returns {SCROLL_DIRECTION} 스크롤 방향 타입
 */
const useScrollDirection = (props: useScrollDirectionType): SCROLL_DIRECTION => {
  const { initialDirection, thresholdPixels } = props;
  const [scrollDir, setScrollDir] = useState(initialDirection || SCROLL_DIRECTION.DOWN);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      setScrollDir(scrollY > lastScrollY ? SCROLL_DIRECTION.DOWN : SCROLL_DIRECTION.UP);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = throttle(() => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    }, 50);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [initialDirection, thresholdPixels]);

  return scrollDir;
};

export default useScrollDirection;
