import * as React from "react";
import { FC } from "react";
import { useRouter } from "next/router";

import TopSideFooter from "@components/base/footer/TopSideFooter";
import BottomSideFooter from "@components/base/footer/BottomSideFooter";
import { GIFT_DELIVERY_URL_PATH } from "@constants/url/internalUrlConstants";
import { FooterStyle } from "./style";

const Footer: FC = () => {
  const router = useRouter();
  const isGiftPage = router.pathname.includes(GIFT_DELIVERY_URL_PATH);

  return (
    <FooterStyle>
      {!isGiftPage && <TopSideFooter />}
      <BottomSideFooter />
    </FooterStyle>
  );
};

export default Footer;
