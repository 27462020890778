import * as React from "react";
import { FC } from "react";

import AmondzLogo from "@components/icon/AmondzLogo";
import { BEJEWEL_BUSINESS_LICENSE_INFORMATION_URL } from "@constants/url/externalUrlConstants";
import { BottomSideFooterStyle } from "./style";

// 이용약관, 개인정보처리방침, 회사 정보에 대한 정보를 담고 있는 하단 mobile footer
const BottomSideFooter: FC = () => {
  return (
    <BottomSideFooterStyle>
      <div className="company-info">
        <AmondzLogo className="amondz-logo" width={60} height={12} />

        <ul>
          <li className="company-address">
            <span>(주) 비주얼</span>
            <span>주소 : 서울특별시 성동구 왕십리로 125, 12층</span>
          </li>
          <li>
            <span>사업자등록번호 : 476-88-00525</span>
          </li>
          <li>
            <span>통신판매업신고 : 2023-서울성동-2043</span>
            <a
              className="business-license-information"
              href={BEJEWEL_BUSINESS_LICENSE_INFORMATION_URL}
              target="_blank"
              rel="noreferrer"
            >
              사업자정보 확인
            </a>
          </li>
          <li>
            <span>고객센터 연락처 : 1668-1753</span>
          </li>
          <li>
            <span>이용시간 : 평일 10:00~18:00 (공휴일 휴무)</span>
          </li>
          <li>
            <span>이메일 : hello@amondz.com</span>
          </li>
          <li className="executive">
            <span>대표자 : 허세일</span>
            <span>개인정보관리책임자 : 오현종</span>
          </li>
          <li>
            <span>호스팅 서비스 : Amazon web service</span>
          </li>
        </ul>
      </div>
    </BottomSideFooterStyle>
  );
};

export default BottomSideFooter;
