import * as React from "react";
import { FC, memo } from "react";

import { IBaseIconProps } from "@components/icon/type";
import Theme from "@styles/theme";

interface IBrandButtonProps extends IBaseIconProps {
  status: boolean;
}

const BrandOnButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.amondz_black }) => (
  <svg
    role="img"
    aria-label="브랜드 활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <circle id="stzj6vhqua" cx="9" cy="9" r="9" />
    </defs>
    <g transform="translate(3 3)" fill="none" fillRule="evenodd">
      <mask id="uvzlio2fqb" fill="#fff">
        <use xlinkHref="#stzj6vhqua" />
      </mask>
      <use fill={fill} xlinkHref="#stzj6vhqua" />
      <path
        stroke="#FFF"
        strokeWidth="1.116"
        mask="url(#uvzlio2fqb)"
        d="M-.383 9.191h18.719M11.106-.422c1.502 3.169 2.253 6.338 2.253 9.506 0 3.17-.75 6.333-2.253 9.492"
      />
      <path
        d="M4.787-.422C6.29 2.747 7.04 5.916 7.04 9.084c0 3.17-.75 6.333-2.253 9.492"
        stroke="#FFF"
        strokeWidth="1.116"
        mask="url(#uvzlio2fqb)"
        transform="matrix(-1 0 0 1 11.828 0)"
      />
    </g>
  </svg>
));

const BrandOffButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.gray_02 }) => (
  <svg
    role="img"
    aria-label="브랜드 비활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke={fill} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M21 12a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z" />
      <path d="M10.73 3.778s-4.285 8.436.024 16.154M14.706 4.023s4.105 8.312-.074 15.909M4 12.2h16.59" />
    </g>
  </svg>
));

const BrandButton: FC<IBrandButtonProps> = ({ width, height, status, fill }) => {
  return (
    <>
      {status ? (
        <BrandOnButton width={width} height={height} fill={fill} />
      ) : (
        <BrandOffButton width={width} height={height} fill={fill} />
      )}
    </>
  );
};

export default memo(BrandButton);
