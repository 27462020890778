import {
  FOOTER_GLOBAL_AMONDZ_JAPAN_BTN_IMAGE_PATH,
  FOOTER_GLOBAL_AMONDZ_US_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_FACEBOOK_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_INSTA_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_KAKAO_BTN_IMAGE_PATH,
  FOOTER_SOCIAL_YOUTUBE_BTN_IMAGE_PATH,
} from "../../static/images";
import {
  AMONDZ_SERVICE_TERMS_CONDITIONS_URL,
  AMONDZ_SERVICE_PERSONAL_INFORMATION_COLLECTION_URL,
  INSTAGRAM_AMONDZ_CHANNEL_URL,
  FACEBOOK_AMONDZ_CHANNEL_URL,
  KAKAO_AMONDZ_CHANNEL_URL,
  YOUTUBE_AMONDZ_CHANNEL_URL,
  BYAMONDZ_JAPAN_URL,
  BYAMONDZ_US_URL,
} from "../../url/externalUrlConstants";

export const FOOTER_TERM_BTN_LIST = [
  {
    title: "이용약관",
    pageUrl: AMONDZ_SERVICE_TERMS_CONDITIONS_URL,
  },
  {
    title: "개인정보 처리방침",
    pageUrl: AMONDZ_SERVICE_PERSONAL_INFORMATION_COLLECTION_URL,
  },
];

export const FOOTER_SOCIAL_BTN_LIST = [
  {
    imgPath: FOOTER_SOCIAL_INSTA_BTN_IMAGE_PATH,
    pageUrl: INSTAGRAM_AMONDZ_CHANNEL_URL,
  },
  {
    imgPath: FOOTER_SOCIAL_YOUTUBE_BTN_IMAGE_PATH,
    pageUrl: YOUTUBE_AMONDZ_CHANNEL_URL,
  },
  {
    imgPath: FOOTER_SOCIAL_FACEBOOK_BTN_IMAGE_PATH,
    pageUrl: FACEBOOK_AMONDZ_CHANNEL_URL,
  },
  {
    imgPath: FOOTER_SOCIAL_KAKAO_BTN_IMAGE_PATH,
    pageUrl: KAKAO_AMONDZ_CHANNEL_URL,
  },
];

export const FOOTER_GLOBAL_BTN_LIST = [
  {
    imgPath: FOOTER_GLOBAL_AMONDZ_JAPAN_BTN_IMAGE_PATH,
    pageUrl: BYAMONDZ_JAPAN_URL,
  },
  {
    imgPath: FOOTER_GLOBAL_AMONDZ_US_BTN_IMAGE_PATH,
    pageUrl: BYAMONDZ_US_URL,
  },
];
