import * as React from "react";
import { FC, memo } from "react";

import { IBaseIconProps } from "@components/icon/type";
import Theme from "@styles/theme";

interface IHomeButtonProps extends IBaseIconProps {
  status: boolean;
}

const HomeOnButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.amondz_black }) => (
  <svg
    role="img"
    aria-label="홈 활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" strokeWidth="1.074">
      <path d="M11.994 3.5 4.5 9.167V20.5h15l-.084-11.333z" fill={fill} stroke={fill} />
      <path stroke="#FFF" strokeLinecap="square" d="M11.937 21.21V14.5" />
    </g>
  </svg>
));

const HomeOffButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.gray_02 }) => (
  <svg
    role="img"
    aria-label="홈 비활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={fill} d="M11.5 13.5h1v6.505h-1z" />
      <path d="M11.994 3.5 19.5 9.167V20.5h-15V9.167L11.994 3.5z" stroke={fill} strokeLinecap="square" />
    </g>
  </svg>
));

const HomeButton: FC<IHomeButtonProps> = ({ width, height, status, fill }) => {
  return (
    <>
      {status ? (
        <HomeOnButton width={width} height={height} fill={fill} />
      ) : (
        <HomeOffButton width={width} height={height} fill={fill} />
      )}
    </>
  );
};

export default memo(HomeButton);
