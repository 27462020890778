import styled from "@styles/themedComponents";

export const TopSideFooterStyle = styled.article`
  .section {
    .section-title,
    .section-list {
      color: ${(props) => props.theme.color.amondz_black};
      letter-spacing: -0.3px;
      cursor: pointer;
      border-bottom: 1px solid ${(props) => props.theme.color.gray_07};
      font-size: 13px;
      line-height: 19px;
    }

    .section-title {
      padding: 15px;
      line-height: 18px;
      font-size: 12px;
      font-weight: 500;

      img {
        float: right;
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }

    .section-list {
      padding: 25px 15px;
      display: flex;
      gap: 24px;

      p {
        line-height: 17px;
        font-size: 11px;
        opacity: 0.7;
      }
    }

    .term-btn-list {
      flex-direction: column;
    }

    .social-btn-list {
      img {
        width: 24px;
        height: 24px;
      }
    }

    .global-btn-list {
      gap: 20px;

      span {
        display: inline-block;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.38px;
        margin-left: 2px;
      }
    }
  }
`;
