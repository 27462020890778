import * as React from "react";
import { FC, memo, ReactNode, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import Link from "next/link";

import AmondzLogo from "@components/icon/AmondzLogo";
import { searchModalVisible } from "@store/modules/search";
import { baseModalToggle, twoButtonModalToggle } from "@store/modules/base";
import Icon from "@components/common/Icon";
import {
  HEADER_CART_BTN_IMAGE_PATH,
  HEADER_MENU_BLACK_BTN_IMAGE_PATH,
  HEADER_SEARCH_BLACK_BTN_IMAGE_PATH,
} from "@constants/static/images";
import { REQUIRED_LOG_IN_INFO_MODAL_CONTENTS } from "@constants/service/auth/modal";
import { BASE_MODAL_NAME_TYPE } from "@constants/enum/baseEnums";
import { INTRO_PAGE_URL_PATH, PAYMENT_CART_URL_PATH } from "@constants/url/internalUrlConstants";
import { HeaderStyle } from "./style";

export interface IHeaderProps {
  className?: string;
  isLoggedIn: boolean;
  title?: ReactNode;
  cartCount: number;
}

const Header: FC<IHeaderProps> = (props: IHeaderProps) => {
  const { className, isLoggedIn, title, cartCount } = props;

  const router = useRouter();
  const dispatch = useDispatch();

  const toggleModal = useCallback(
    () =>
      dispatch(
        baseModalToggle({
          targetModal: BASE_MODAL_NAME_TYPE.HEADER_MENU_MODAL,
        }),
      ),
    [],
  );

  // 장바구니 메뉴 버튼 클릭했을 때 로그인하지 않았으면 모달 노출
  const onRequiredAuthInfoModal = useCallback(() => {
    dispatch(
      twoButtonModalToggle({
        visible: true,
        msg: REQUIRED_LOG_IN_INFO_MODAL_CONTENTS.MSG,
        subMsg: REQUIRED_LOG_IN_INFO_MODAL_CONTENTS.SUB_MSG,
        onClickRightBtn: () => router.push(PAYMENT_CART_URL_PATH),
      }),
    );
  }, []);

  // 장바구니 갯수 메모이제이션
  const calcCartCount = useMemo(() => Math.min(99, cartCount || 0), [cartCount]);

  return (
    <HeaderStyle className={className}>
      <div className="header-wrap">
        <Icon className="menu-btn" src={HEADER_MENU_BLACK_BTN_IMAGE_PATH} onClick={toggleModal} />
        {title ? (
          <div className="header-title">{title}</div>
        ) : (
          <Link href={INTRO_PAGE_URL_PATH} className="amondz-log">
            <AmondzLogo width={97} height={20} />
          </Link>
        )}

        <ul className="icon-menu">
          <li>
            <Icon
              src={HEADER_SEARCH_BLACK_BTN_IMAGE_PATH}
              className="search-btn"
              onClick={() => dispatch(searchModalVisible({ isSearchModalVisible: true }))}
            />
          </li>
          {isLoggedIn ? (
            <li>
              <Link href={PAYMENT_CART_URL_PATH} className="cart-btn">
                <Icon src={HEADER_CART_BTN_IMAGE_PATH} />
                <div className="cart-count">
                  <span>{calcCartCount}</span>
                </div>
              </Link>
            </li>
          ) : (
            <li>
              <Icon src={HEADER_CART_BTN_IMAGE_PATH} className="cart-btn" onClick={onRequiredAuthInfoModal} />
            </li>
          )}
        </ul>
      </div>
    </HeaderStyle>
  );
};

export default memo(Header);
