import styled from "@styles/themedComponents";

interface IBottomTabStyleProps {
  isTabVisible: boolean;
}

interface IBottomTabTextStyleProps {
  isSelected?: boolean;
}

export const BottomTabStyle = styled.div<IBottomTabStyleProps>`
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: ${({ theme }) => theme.zIndex.bottomFixedArea};
  bottom: 0;
  padding: 5px 24px 6px;
  background-color: ${({ theme }) => theme.color.amondz_white};
  border-top: 1px solid ${(props) => props.theme.color.gray_07};
  transform: ${({ isTabVisible }: IBottomTabStyleProps) => {
    if (!isTabVisible) return "translateY(50px)";
  }};
`;

export const BottomTabUlStyle = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  & > li {
    & > a {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }

    & > img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const BottomTabTextStyle = styled.span<IBottomTabTextStyleProps>`
  font-family: ${({ theme }) => theme.fonts.notoSansCJKkr};
  font-size: 10px;
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  color: ${({ theme, isSelected }) => (isSelected ? theme.color.amondz_black : theme.color.gray_02)};
`;
