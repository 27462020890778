import styled from "@styles/themedComponents";

export const HellobarWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  padding: 0 10px 0 15px;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  background-color: ${(props) => props.theme.color.amondz_black};
  z-index: ${(props) => props.theme.zIndex.header};
`;

export const HellobarTitle = styled.span`
  // MOBILE
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: ${(props) => props.theme.color.amondz_white};
`;

export const HelloBarImg = styled.span`
  font-size: 16px;
`;

export const DiscountBtn = styled.div`
  margin-left: auto;
  width: 64px;
  height: 26px;
  font-size: 11px;
  border-radius: 13px;
  font-weight: 500;
  letter-spacing: -0.33px;
  line-height: 12px;
  padding: 8px 11px 7px;

  background-color: ${(props) => props.theme.color.amondz_white};
  color: ${(props) => props.theme.color.amondz_black};
`;

export const HellobarExitBtnImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;
