import { BOTTOM_TAB_QUERY_INDEX_SIGNATURE_TYPE } from "@store/modules/base";
import {
  BRAND_LIST_PAGE_URL_PATH,
  CATEGORY_PAGE_URL_PATH,
  INTRO_PAGE_URL_PATH,
  LIKE_PAGE_URL_PATH,
  MY_PAGE_URL_PATH,
} from "@constants/url/internalUrlConstants";

export const BOTTOM_TAB_QUERY: BOTTOM_TAB_QUERY_INDEX_SIGNATURE_TYPE = {
  // 탭 목록에 있는 페이지가 아닐 경우
  EMPTY: -1,
  // 홈
  HOME: 0,
  // 카테고리
  CATEGORY: 1,
  // 브랜드
  STORE: 2,
  // 좋아요
  LIKE: 3,
  // 마이페이지
  MYPAGE: 4,
} as const;

// 하단 탭 텍스트
export const BOTTOM_TAB_TEXT = {
  [BOTTOM_TAB_QUERY.HOME]: "홈",
  [BOTTOM_TAB_QUERY.CATEGORY]: "카테고리",
  [BOTTOM_TAB_QUERY.STORE]: "브랜드",
  [BOTTOM_TAB_QUERY.LIKE]: "좋아요",
  [BOTTOM_TAB_QUERY.MYPAGE]: "마이",
} as const;

// 하단 탭 리스트
export const BOTTOM_TAB_LIST = [
  {
    name: BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.HOME],
    href: INTRO_PAGE_URL_PATH,
  },
  {
    name: BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.CATEGORY],
    href: CATEGORY_PAGE_URL_PATH,
  },
  {
    name: BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.STORE],
    href: BRAND_LIST_PAGE_URL_PATH,
  },
  {
    name: BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.LIKE],
    href: LIKE_PAGE_URL_PATH,
  },
  {
    name: BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.MYPAGE],
    href: MY_PAGE_URL_PATH,
  },
];
