import * as React from "react";
import { FC, memo } from "react";

import { IBaseIconProps } from "@components/icon/type";
import Theme from "@styles/theme";

interface IMypageButtonProps extends IBaseIconProps {
  status: boolean;
}

const MypageOnButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.amondz_black }) => (
  <svg
    role="img"
    aria-label="마이페이지 활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M21 12a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"
        stroke={fill}
        strokeWidth="1.147"
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(8.64 9.3)" fill="#FFF" fillRule="nonzero">
        <circle cx="6.766" cy=".967" r="1" />
        <circle cx=".967" cy=".967" r="1" />
      </g>
      <path
        d="M9.896 15.42c.563.671 1.55 1.117 2.674 1.117 1.13 0 2.122-.45 2.684-1.129"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
));

const MypageOffButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.gray_02 }) => (
  <svg
    role="img"
    aria-label="마이페이지 비활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M21 12a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g transform="translate(8.64 9.3)" fill={fill} fillRule="nonzero">
        <circle cx="6.766" cy=".967" r="1" />
        <circle cx=".967" cy=".967" r="1" />
      </g>
      <path
        d="M9.896 15.42c.563.671 1.55 1.117 2.674 1.117 1.13 0 2.122-.45 2.684-1.129"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
));

const MypageButton: FC<IMypageButtonProps> = ({ width, height, status, fill }) => {
  return (
    <>
      {status ? (
        <MypageOnButton width={width} height={height} fill={fill} />
      ) : (
        <MypageOffButton width={width} height={height} fill={fill} />
      )}
    </>
  );
};

export default memo(MypageButton);
