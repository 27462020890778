import * as React from "react";
import { useRouter } from "next/router";

import Header from "@components/base/header/Header";
import Footer from "@components/base/footer/Footer";
import BottomTab from "@components/base/bottomTab";
import Hellobar from "@views/common/modal/HelloBar";
import { ATypeLayoutStyle } from "./style";

interface IATypeLayoutProps {
  isLoggedIn: boolean;
  cartCount: number;
}

const ATypeLayout = (props: React.PropsWithChildren<IATypeLayoutProps>) => {
  const { children, isLoggedIn, cartCount } = props;

  const router = useRouter();

  return (
    <ATypeLayoutStyle>
      <Hellobar />
      <Header isLoggedIn={isLoggedIn} cartCount={cartCount} />

      <main>{children}</main>
      <Footer />
      {/* 인증 관련된 페이지는 하단 탭 비노출 */}
      {!router.asPath.includes("auth") && <BottomTab />}
    </ATypeLayoutStyle>
  );
};

export default ATypeLayout;
