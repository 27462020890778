import * as React from "react";
import { FC, memo } from "react";

import { IBaseIconProps } from "@components/icon/type";
import Theme from "@styles/theme";

interface ICategoryButtonProps extends IBaseIconProps {
  status: boolean;
}

const CategoryOnButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.amondz_black }) => (
  <svg
    role="img"
    aria-label="카테고리 활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(3 4)" fill={fill} fillRule="evenodd">
      <path d="M.5 13.7h17a.5.5 0 0 1 .5.5V16a.5.5 0 0 1-.5.5H.5A.5.5 0 0 1 0 16v-1.8a.5.5 0 0 1 .5-.5z" />
      <rect y="6.7" width="18" height="2.8" rx=".5" />
      <path d="M.5 0h17a.5.5 0 0 1 .5.5v1.762a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5V.5A.5.5 0 0 1 .5 0z" />
    </g>
  </svg>
));

const CategoryOffButton: FC<IBaseIconProps> = memo(({ width, height, fill = Theme.color.gray_02 }) => (
  <svg
    role="img"
    aria-label="카테고리 비활성화"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(3 4)" fill={fill} fillRule="evenodd">
      <rect y="15" width="18" height="1" rx=".2" />
      <rect y="8" width="18" height="1" rx=".2" />
      <rect width="18" height="1" rx=".2" />
    </g>
  </svg>
));

const CategoryButton: FC<ICategoryButtonProps> = ({ width, height, status, fill }) => {
  return (
    <>
      {status ? (
        <CategoryOnButton width={width} height={height} fill={fill} />
      ) : (
        <CategoryOffButton width={width} height={height} fill={fill} />
      )}
    </>
  );
};

export default memo(CategoryButton);
