import React, { useEffect, useState, useCallback, JSX } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { BOTTOM_TAB_QUERY_TYPE, BOTTOM_TAB_TEXT_TYPE } from "@store/modules/base";
import useScrollDirection from "@lib/utility/useScrollDirection";
import LikeButton from "@components/icon/LikeButton";
import HomeButton from "@components/icon/HomeButton";
import CategoryButton from "@components/icon/CategoryButton";
import BrandButton from "@components/icon/BrandButton";
import MypageButton from "@components/icon/MypageButton";
import { SCROLL_DIRECTION } from "@constants/enum/baseEnums";
import { BOTTOM_TAB_LIST, BOTTOM_TAB_QUERY, BOTTOM_TAB_TEXT } from "@constants/service/base/bottomTab";
import {
  BRAND_LIST_PAGE_URL_PATH,
  CATEGORY_PAGE_URL_PATH,
  INTRO_PAGE_URL_PATH,
  LIKE_PAGE_URL_PATH,
  MY_PAGE_URL_PATH,
} from "@constants/url/internalUrlConstants";
import Theme from "@styles/theme";
import { BottomTabStyle, BottomTabTextStyle, BottomTabUlStyle } from "./style";

const BottomTab = (): JSX.Element => {
  const { pathname } = useRouter();

  // 선택한 탭 종류
  const [selectTab, setSelectTab] = useState<BOTTOM_TAB_QUERY_TYPE>(BOTTOM_TAB_QUERY.EMPTY);
  // 하단탭 노출 여부(스크롤 방향에 따라)
  const [isTabVisible, setTabVisible] = useState<boolean>(true);

  const scrollDirection = useScrollDirection({
    initialDirection: SCROLL_DIRECTION.UP,
    thresholdPixels: 10,
  });

  // 스크롤 방향에 따라 하단 탭 노출여부 결정
  useEffect(() => {
    const onScroll = (): void => {
      if (scrollDirection === SCROLL_DIRECTION.UP) {
        setTabVisible(true);
      } else {
        setTabVisible(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDirection]);

  // 선택한 탭 업데이트
  useEffect(() => {
    switch (true) {
      case pathname.includes(INTRO_PAGE_URL_PATH):
        setSelectTab(BOTTOM_TAB_QUERY.HOME);
        return;
      case pathname.includes(CATEGORY_PAGE_URL_PATH):
        setSelectTab(BOTTOM_TAB_QUERY.CATEGORY);
        return;
      case pathname.includes(BRAND_LIST_PAGE_URL_PATH):
        setSelectTab(BOTTOM_TAB_QUERY.STORE);
        return;
      case pathname.includes(LIKE_PAGE_URL_PATH):
        setSelectTab(BOTTOM_TAB_QUERY.LIKE);
        return;
      case pathname.includes(MY_PAGE_URL_PATH):
        setSelectTab(BOTTOM_TAB_QUERY.MYPAGE);
        return;
      default:
        setSelectTab(BOTTOM_TAB_QUERY.EMPTY);
        return;
    }
  }, [pathname]);

  // 선택 된 탭인지 확인하는 함수
  const isSelectedTab = useCallback((name: BOTTOM_TAB_TEXT_TYPE) => name === BOTTOM_TAB_TEXT[selectTab], [selectTab]);

  // 탭 아이콘을 그려주는 함수
  const drawTabIcon = useCallback((width: number, height: number, name: string, status: boolean, fill: string) => {
    switch (name) {
      case BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.CATEGORY]:
        return <CategoryButton width={width} height={height} status={status} fill={fill} />;
      case BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.STORE]:
        return <BrandButton width={width} height={height} status={status} fill={fill} />;
      case BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.LIKE]:
        return <LikeButton width={width} height={height} status={status} fill={fill} />;
      case BOTTOM_TAB_TEXT[BOTTOM_TAB_QUERY.MYPAGE]:
        return <MypageButton width={width} height={height} status={status} fill={fill} />;
      default:
        return <HomeButton width={width} height={height} status={status} fill={fill} />;
    }
  }, []);

  return (
    <BottomTabStyle className="bottom-tab" isTabVisible={isTabVisible}>
      <BottomTabUlStyle>
        {BOTTOM_TAB_LIST.map((tab) => (
          <li key={tab.name}>
            <Link href={tab.href}>
              {drawTabIcon(
                24,
                24,
                tab.name,
                isSelectedTab(tab.name),
                isSelectedTab(tab.name) ? Theme.color.amondz_black : Theme.color.gray_02,
              )}
              <BottomTabTextStyle isSelected={isSelectedTab(tab.name)}>{tab.name}</BottomTabTextStyle>
            </Link>
          </li>
        ))}
      </BottomTabUlStyle>
    </BottomTabStyle>
  );
};

export default BottomTab;
